<template>
  <div>
    <div class="content">
      <div class="SearchBar">
        <el-button  @click="closeadd" size="mini" class="backbtn">返回</el-button>
        <el-form label-position="right" label-width="110px">
          <el-row class="row">
            <el-col :span="8">
              模板名称:{{ form.tplName }}
            </el-col>
            <el-col :span="8">
              用户分类:  {{ form.userTypeName }}
            </el-col>
            <el-col :span="8">
              启用状态: {{ form.generalStatus==0 ? '禁用' : '启用' }}
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="8">
              安检项:
            </el-col>
          </el-row>
          <el-row>
            <el-table
                ref="tableData"
                :data="form.arumScuItems"
                style="margin:0px 0px 20px 45px"
                border
                :stripe="true"
            >
              <el-table-column
                  type="index"
                  label="序号"
                  align="center"
                  width="60"
              >
              </el-table-column>
              <el-table-column prop="entryTypeName" label="安检对象" align="center" show-overflow-tooltip></el-table-column>
              <el-table-column prop="content" label="安检内容" align="center" show-overflow-tooltip></el-table-column>
              <el-table-column prop="level" label="隐患等级" align="center" show-overflow-tooltip></el-table-column>
            </el-table>
          </el-row>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { scuTemplateDetails } from "@/RequestPort/peopleUser/people";
export default {
  data() {
    return {
      form:{},
      tableData: [
        {name:'大清洗'},
        {name:'小清洁'},
      ], //  表格数据
    }
  },
  mounted() {
    this.particulars(this.$route.query.id)
  },
  methods: {
    closeadd() {
      // this.$router.go(-1)
      this.$router.push({ path: '/home/checkDict', query: { first: 2, screcd:4 } })
    },
    particulars(id) {
      scuTemplateDetails({id}).then(res =>{
        this.form = res.data
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  background: #f3f4f8;;
  width: 100%;
  border-radius: 6px;
  overflow:auto;
  // height: calc(100vh - 174px);
}
.SearchBar {
  border-radius: 5px;
  margin: 10px;
  height: calc(100vh - 174px);
  background-color: #ffffff;
}
.backbtn{
  margin:30px 0px 40px 30px;
  width: 82px;
  height: 36px;
}
.row{
  margin-left: 45px;
  margin-bottom: 20px;
}
.row2{
  margin-left: 10px;
  margin-top: 10px;
}
.titleDiv{
  margin: 26px 0px 0px 30px;
}
</style>
